import styled from 'styled-components';
import { borderGrey, darkText, titlePink, white } from '../../constants/theme';

export const FaqsSection = styled.div`
  padding: 40px 0;
  .faq-div {
    border-bottom: 1px solid ${borderGrey};
  }
  .toggle-btn {
    background-color: transparent;
    color: ${darkText};
    border: none;
    width: 100%;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    text-align: left;
    padding: 30px 0;
    .question-holder {
      display: inline-block;
      position: relative;
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
    svg {
      color: ${titlePink};
      font-size: 32px;
      float: right;
      position: relative;
      &:hover {
        color: #a52a69 !important;
      }
    }
  }
  .toggle-btn:hover {
    outline: none;
    background-color: ${white};
    box-shadow: none;
    svg {
      color: #a52a69 !important;
    }
  }
  .toggle-btn:focus {
    outline: none;
    background-color: ${white};
    box-shadow: none;
  }
  .toggle-btn:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
  .toggle-btn:active {
    outline: none;
    background-color: ${white};
    box-shadow: none;
  }
  .toggle-btn::-moz-focus-inner {
    border: none;
    background: ${white};
  }
  .btn:hover {
    outline: none;
    background-color: ${white};
    box-shadow: none;
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: ${white};
    color: ${darkText};
    box-shadow: none;
    border-color: ${borderGrey};
  }
  .faq-card {
    border: none;
    padding: 40px 0 0 0;
  }
  .faq-card-body {
    padding: 0 0 29px 35%;
    ul {
      list-style: none;
      padding: 10px 0 20px;
      li {
        font-size: 18px;
        line-height: 32px;
        padding: 0 0 10px 30px;
        strong {
          font-weight: bold;
        }
        a {
          color: ${titlePink};
          &:hover {
            text-decoration: none;
            color: #a52a69 !important;
          }
        }
      }
      li::before {
        content: '•';
        color: #503291;
        padding-right: 15px;
        font-size: 22px;
        display: inline-block;
        margin-left: -28px;
      }
    }
    p {
      padding-bottom: 10px;
    }
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 40px 20px;
    .faq-card-body {
      padding: 0 0 29px 15%;
    }
    .toggle-btn {
      .question-holder {
        display: inline-block;
        width: 95%;
      }
      svg {
        color: ${titlePink};
        font-size: 32px;
        position: absolute;
        right: 20px;
      }
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 40px 20px;
    .faq-card-body {
      padding: 0 0 0 0%;
    }
    .toggle-btn {
      .question-holder {
        display: inline-block;
        width: 85%;
      }
      svg {
        color: ${titlePink};
        font-size: 32px;
        position: absolute;
        right: 20px;
      }
    }
  }
`;
