import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Col } from 'reactstrap';
import { Fonts, FaqsHero, PageBody } from '../global.css';
import FaqSection from '../components/faq-section/faq-section';
import { getCompanyData, replaceCompanyName } from '../constants/config';
import Loading from '../components/loading/loading';
import { registerTrackEvent } from '../helpers/tagManager';

const Faq = ({ data }) => {
  const [scriptsLoading, setScriptsLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScriptsLoading(false);
    }
  }, []);

  const loading = () => {
    setPageLoading(false);
  };

  return scriptsLoading ? (
    ''
  ) : (
    <PageBody>
      <div className={!pageLoading ? 'hidden' : ''}>
        <Loading />
      </div>
      <div className={pageLoading ? 'hidden' : ''}>
        <Layout meta={replaceCompanyName(data.faqEnJson, 'seo').seo}>
          <div className={'content-container'}>
            <Fonts>
              <FaqsHero>
                <Col md={4} tag={'h1'}>
                  {replaceCompanyName(data.faqEnJson.title)}
                </Col>
                <Col md={8}>
                  <img
                    src={data.faqEnJson.heroImg.publicURL}
                    alt={data.faqEnJson.heroImgAlt}
                    onLoad={loading}
                    onClick={registerTrackEvent({
                      category: 'Image',
                      action: 'Click',
                      label: replaceCompanyName(data.faqEnJson.title),
                    })}
                  />
                </Col>
              </FaqsHero>
              <FaqSection
                faqItems={data.faqEnJson.faqsItem.map((item) => {
                  return {
                    title: replaceCompanyName(getCompanyData(item, 'title')),
                    content: replaceCompanyName(
                      getCompanyData(item, 'content')
                    ),
                  };
                })}
              />
            </Fonts>
          </div>
        </Layout>
      </div>
    </PageBody>
  );
};

Faq.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Faq;

export const query = graphql`
  query Faq {
    faqEnJson {
      seo {
        pageTitle
        pageDescription
      }
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      heroImgAlt
      heroImg {
        publicURL
      }
      video
      video_merck
      videoTitle
      faqsItem {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        content_merck {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
