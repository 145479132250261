import React, { useEffect, useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import PropTypes from 'prop-types';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { FaqsSection } from './faq-section.css';
import Modal from '../modal/modal';
import Redirect from '../redirect/redirect';
import { trackEvent } from '../../helpers/tagManager';

const FaqSection = ({ faqItems }) => {
  const [showExternalLinkModal, setShowExternalLinkModal] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [collapse, setCollapse] = useState(
    faqItems.map(() => {
      return false;
    })
  );

  useEffect(() => {
    setModalOpen(true);
  }, [showExternalLinkModal]);

  const hideModal = () => {
    setModalOpen(false);
  };

  const toggle = itemIndex => {
    let col = collapse;
    setCollapse(
      col.map((value, index) => {
        if (index !== itemIndex) return false;
        const open = !value;

        trackEvent({
          category: 'FAQ',
          action: open ? 'Open' : 'Close',
          label: faqItems[itemIndex].title,
        });

        return open;
      })
    );
  };

  const handleClick = event => {
    if (
      event &&
      event.target &&
      event.target.href &&
      (event.target.href.indexOf('http://') > -1 ||
        event.target.href.indexOf('https://') > -1)
    ) {
      event.preventDefault();
      event.stopPropagation();
      let href = event.target.href;
      if (showExternalLinkModal.length) {
        setShowExternalLinkModal('');
      }
      setTimeout(() => {
        setShowExternalLinkModal(href);
      }, 100);
    }
  };

  return (
    <FaqsSection>
      {faqItems.map((items, index) => (
        <div className={'faq-div'} key={index}>
          <Button onClick={() => toggle(index)} className={'toggle-btn'}>
            <h3 className={'question-holder'}>{items.title}</h3>
            {collapse && collapse[index] ? <FaMinusCircle /> : <FaPlusCircle />}
          </Button>
          <Collapse isOpen={collapse[index]}>
            <Card className={'faq-card'} onClick={event => handleClick(event)}>
              <CardBody
                dangerouslySetInnerHTML={{
                  __html: items.content.childMarkdownRemark.html,
                }}
                className={
                  'faq-card-body' +
                  (index === faqItems.length - 1 ? ' lastItem' : '')
                }
              />
            </Card>
          </Collapse>
        </div>
      ))}
      {showExternalLinkModal && showExternalLinkModal.length ? (
        <Modal
          autoOpen={true}
          open={modalOpen}
          type={'redirect'}
          hideModal={hideModal}
          trackEvent={{
            label: "Redirect Popup"
          }}
        >
          <Redirect hideModal={hideModal} url={showExternalLinkModal} />
        </Modal>
      ) : (
        undefined
      )}
    </FaqsSection>
  );
};
FaqSection.propTypes = {
  faqItems: PropTypes.array.isRequired,
};
export default FaqSection;
